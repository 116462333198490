.floatbutton {
    position: fixed;
    bottom: 50%;
    top: 30%;
    right: 0;
    z-index: 1000;
  }
  
  .floatbutton button {
    background-color: #01A3d8;
    color: white;
    border: none;
    border-radius: 8px 0 0 8px;
    padding: 16px;
    font-size: 15px;
    /* padding: 18px;
    font-size: 20px; */
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    font-weight: 500;


    /* background-color: #01A3d8;
    color: white;
    border: none;
    border-radius: 50px 0 0 50px;
    padding: 12px 50px;
    font-size: 15px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    font-weight: bold; */
   
    
  }
  
  .floatbutton button:hover {
    transform: scale(1.1);
  }

  @media (min-width:300px) and (max-width:767px) {
    .floatbutton button {
      font-size: 8px;
      padding: 12px;
      border-radius: 4px 0 0 4px;

    }
    .floatbutton{
      top: 50%;
    }
  }
  