/* BackToTopButton.css */
.backbutton {
    position: fixed;
    bottom: 50px; /* Adjust as needed */
    right: 30px;  /* Adjust as needed */
  background-color:#ffff;
  border-radius: 50px;
   z-index: 1;
    cursor: pointer;

  }
  
 .arrowback{
    width: 60px;
    height: 60px;
    color: #01a3d8;
   
 }
  