.footer_main{
    width: 80%;
  
    background-color: white;
    margin: auto;

    padding-top: 50px;
}

.footer_logo{
    margin-left: -107px;

    width: 100%;

}
.socialmedia{
    font-size: 25px;
 
    color:#828282
}
.footer_link{
    cursor: pointer;
}

.footer_link:hover{
    color:#06A1D7
}

.footer_heading{
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    line-height: 24px;

}
.footer_links{
    font-size: 16px;
    line-height: 24px;
    color:#333333;
    font-weight: 400;
    text-decoration: none;
}
.footer_linksinsta{
    font-size: 16px;
    line-height: 24px;
     margin-left: 10px;
    font-weight: 400; 
}
.footerpara {
    font-size: 50px;
    margin-bottom: 0px;
    margin-left: 90px;
    font-weight: bold;
    line-height: 48px;
}

.footerparato{
    font-size: 50px;
    margin-bottom: 0px;
    margin-left: 90px;
    font-weight: bold;
    line-height: 48px;
    color: #06A1D7;
}

.para{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 20px;
}

.footertag{
  text-align: center;
  padding-top: 17px;

}

.footertag p{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  .footertagp{
    margin-top: -14px;

}
.smallscreenfooter{
    display: none;
}

.newbook{
    margin-top: 10px;
    width: 75px;
    height: 108px;
    

}
@media (max-width:1024px) {
    .footer_main{
        width: 100%;
   
        display: block;
    }
    .footer_logo{
        width: 80%;
  margin-left: 0px;
    }

    .footerpara{
        font-size: 35px;
        margin-top: 60px;
        line-height: 40px;
    }
    .smallscreenfooter{
        display: block;
    }
    .smallscreenfootheading{
        display: flex;
        justify-content: space-around;
        padding:  0px;
    }
    .bigscreenfooter{
        display: none;
    }
    .footerpara{
        margin-left: 0px;
    }
    .footerpara span{
        font-size: 35px;
        margin-top: 60px;
        line-height: 40px;
        font-weight: bold;
        color: #01A3D8;
    }
    .newbook{
        width: 100%;
        height: auto;
      
      }
      .follow{
        text-align: center;
      }
    }

    @media (min-width:300px) and (max-width:767px){
     .smallscreenfootheading{
        padding:20px 20px;
     }
       .footerpara{
        font-size: 25px;
        margin-top: 30px;
        line-height: 30px;
    
       }
       .follow{
        text-align: center;
       }
       .newbook{
         width: 100%;
         height: auto;
       
       }
       .smallscreenfootheading{
        display: flex;
        justify-content: space-between;
        padding:  20px 20px;
    }
      
    }