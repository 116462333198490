.dontaion_row {
  width: 70%;
  margin: auto;
  /* color: white; */
  position: relative;
  z-index: 999;
}
.centername {
  /* color: #fff; */
  font-size: 46px;
  font-weight: 600;
  line-height: 51.25px;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 15px;
  /* padding-left: 10px; */
}

.centerLocation {
  /* color: #fff; */
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  margin-bottom: 15px;
  /* padding-left: 10px; */
}
.paymentForm {
  background:#01a3d8;
  padding: 40px 20px 40px 20px;
  /* border: 1px solid #E1E1E1; */
  /* box-shadow: 0px -3px 49px 0px #00000040; */
}
.infoform{
  background:#01a3d8;
  padding: 40px 40px;
}
.maindonation{
  padding: 50px 0px;
}
.donationpayment h3{
  color: #ffffff;
  font-size: 26px;
  line-height: 30px;
  margin: 20px 0;
    font-weight: bold;
}
.paymentHead {

  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 20px;
  color:#ffffff;
}
.donationcomment{
  color: #ffffff;
  font-size: 16px;
}

.paymentformmain{
  padding: 0 0 0 15px;
}

.maincenterform{
  padding: 0 15px 0 0;
}
.commentBox {
  width: 100%;
  border: none;
  white-space: pre-wrap;
  border-bottom: 1px solid #bbbcbe;
  padding: 10px 20px 100px 20px;
}
.commentBox:focus {
  outline: none;
  box-shadow: none; /* Remove any default box shadow */
}

.selectAmount {
  border: 1px solid #e1e1e1;
  padding: 20px 40px;
  cursor: pointer;
}
.selectAmount:hover {
  color: #06a1d7;
  border: 1px solid #06a1d7;
}
.imgText {
  width: 50%;
  font-size: 20px;
}
.noborder tr td {
  border: none !important;
  border-bottom: none !important;
}
.donationBtn {
  border-color: #06a1d7 !important;
  color: #06a1d7 !important;
}
.donationBtn:hover {
  background-color: #006f96 !important;
  border-color: #006f96 !important;
  color: white !important;
}
/* .donationcontainer{
  background-image: url("../../../public/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: center;
  position: relative;

} */
/* .donationcontainer::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
} */
.donatebackgroundcolor{
background-color: #06a1d7;
padding: 65px;
}
.donatebackgroundcolor h1{
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  color: #ffffff;
}
.donateinputcontainer{
  /* padding: 20px; */
  padding: 10px 20px;
  /* background-color: #ffffff; */
  border: 1px solid #ffffff;
}
.innerlabel span{
  font-size: 30px;
  line-height: 34px;
    font-weight: bold;
    color: #01a3d8;
}
.donateinput2container{
  border: 1px solid #ffffff;
  background-color: #ffffff;
}
.donateradio{
  display: flex;
}
.donatethank h1{

  font-size: 56px;
  line-height: 60px;
  font-weight: bold;
  /* color: #ffffff; */
  /* margin-top: 56px; */
  margin-left: 23px;


}
input[type="radio"] {
  appearance: none; /* Remove default radio button appearance */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  border: 1px solid #01a3d8; /* 1px solid blue border */
  border-radius: 50px; /* Circle shape */
  width: 25px; /* Circle size */
  height: 25px; /* Circle size */
  outline: none;
  cursor: pointer;

}
.labelcontent{
  display: flex;
  align-items: center;
}
.donationtable{
  padding: 20px;
    background: #ffffff;
    margin-bottom: 40px;
}

input[type="radio"]:checked {
 background-color: #ffffff; /* 1px solid blue border */
position: relative;
  width: 25px; /* Circle size */
  height: 25px; /* Circle size */
  outline: none;
  cursor: pointer;

}

/* Show the inner blue circle when the radio button is checked */
input[type="radio"]:checked::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  background-color: #01a3d8;
  border-radius: 50%;
  pointer-events: none;
}




.innerlabel p{
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
}

.labellink{
 
  font-size: 16px;
  color: #ffffff;
 
}

.labellinkemail{
    font-size: 16px;
  color: #ffffff;
  margin-top: 24px;
}
.labellink {
  margin-top: 24px;
  /* margin-bottom: 61px; */
}
.donatebackgroundcolor h3{
  font-size: 28px;
    line-height: 36px;
    margin-bottom: 25px;
    color:#ffffff;

}
.donationlabel{
  padding: 21px;
  /* padding: 28px 62px 23px 32px; */
}
.donatecol{
  padding: 57px 0px 126px 0px;
}
.inforcol{
 
    padding: 57px 0px 126px 0px;

}
.paycol{
  padding: 57px 0px 126px 0px;
}
.selectedLabel2{

 background-color: #01a3d8;
 border: 1px solid #ffffff;
 width:   100%;
}
.selectedLabel2 span{
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
display: inline-block;
margin-left: 20px;
color: #ffffff;
}

.labeldonate span{
  font-size: 18px;
    line-height: 28px;
    font-weight: bold;
  display: inline-block;
  margin-left: 20px;
}
.labeldonate{
  border: 1px solid #ffffff;
}
.labeldonate2 span{
  font-size: 20px;
    line-height: 28px;
    font-weight: bold;
  display:inline-block;
  margin-left: 20px;

}
.labeldonate2{
  background-color: #ffffff;
  width: 100%;
}
.labeldonate{
  background-color: #ffffff;
  width: 100%;
}
.donationbuttoncontainer{

  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-top: 30px;
}
.donationbuttoncontainer a{
  color: #ffffff;
  font-size: 16px;
}
.donationbuttoncontainer button{
  font-size: 18px;
    /* width: 256px;
    height: 56px; */
    border-radius: 47px;
    border: none;
    background-color: #ffffff;
    font-weight: bold;
    line-height: 28px;
    width: 100%;
    padding: 15px 0;
  
  
}
.innerlabel{
  display: flex;
    align-items: center;
}
.innerlabel span{
  margin:10px
}

.selectedLabel{
  background-color: #ffffff;
  border: 1px solid #ffffff;
  background-color: #06a1d7;
  color:#ffffff;
  padding: 10px 20px;
}
.selectedLabel span{
  font-size: 30px;
    line-height: 34px;
    font-weight: bold;
  
  color:#ffffff;

}
.labelesign{
  width: 100%;
  height: 48px;
}
.smallscreeninput{
   
  display: flex;
align-items: center;

}
.amountdonation{
  margin-bottom: 24px;
}
/* input[type='text']{


} */
.inputboxtext{
  width: 100% !important;
  padding: 10px;
  border: 0 !important;
  outline: 0 !important;
}
.donationsucesscontainer{
  background-image: url("../../../public/still.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 560px;
  display: flex;
  justify-content: center;
  align-items: end;
}
.donationthankcontainer{
  background-color: #01a3d8;
}
.donationcontainertext h1{
  font-size: 67px;
    line-height: 60px;
    font-weight: bold;
    color: #ffffff;
}
.donationcontainertext p{
  font-size: 30px;
  /* line-height: 60px; */
  color: #ffffff;
  font-weight: bold;

}
.donationcontainertext{
  text-align: center;
  padding: 0px 100px 40px;

}
.thankcontainertextp p{
  font-size: 34px;
  line-height: 42px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;

}
.thankcontainertext h3{
  font-size: 42px;
    line-height: 44px;
    font-weight: bold;
    color: #ffffff;
    margin-left: 25%;
}
.thankcontainertext{
  display: flex;
}
.donationthankcontainer{
  padding: 60px 0px
}
.lettermaincontainer h2{
  font-size: 40px;
  font-weight: bold;
  line-height: 44px;
}

.lettermaincontainer p{
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 10px;
}
.lettermaincontainer p span{
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.lettermaincontainer a{
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #191919;
  text-decoration: none;
}
.lettermaincontainer h5{
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: #191919;
}
.letterbutton button{
  background-color: #01A3D8;
    color: white;
    border-radius: 30px;
    font-size: 18px;

    margin: 50px 0px;

    border: none;
    padding: 10px 32px;
}
.textimgconatiner{
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 50px 0;
}
.pcontainer p{
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 0px;
}
.letterimagecontainer  img {
  width: 40px;
  height: 40px;
  margin-left: 14px;
  text-decoration: none !important;

}

.informationscreeninput span{
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #ffffff;
}
.informationinput{

    font-size: 15px !important;
    line-height: 32px;
    font-weight: 400;
    outline: 0;
    width: 100% !important;
    height: auto !important;
    border-radius: 0px !important;
    padding:20px !important
}

.informationtextp p{
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  color: #ffffff;
}
.informationthank h1{
  margin-left: 35px;
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  color: #ffffff;
   

}
.informationbuttoncontainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 45px;
}
.informationbuttoncontainer a{
  color: #ffffff;
  font-size: 16px;
}
.informationbuttoncontainer button{
  font-size: 22px;
  width: 256px;
  height: 56px;
  border-radius: 47px;
  border: none;
  background-color: #ffffff;
  font-weight: bold;
  line-height: 28px;
}
.informationnputmaincontainer{
  margin-bottom: 50px;

}
.inforcontainer{
  background-image: url("../../../public/inform.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.paymentcontainer{
  background-color: #ffffff;
  /* display: flex; */
 
}
.youtubevideo{
  border: 2px solid #01a3d8;
  padding: 7px;
}
.customAmountInput{
  display: flex;
}

.currency{
  font-size: 30px;
    padding: 0 20px;
    color: #999;
    /* border: 2px solid #ccc; */
    border-right: 0;
    line-height: 2.5;
    border-radius: 0;
    background: white;
    outline: none;
}
.prayhead{
  font-size: 46px;
    font-weight: 600;
    text-align: center;
}

.praypara{
  font-size: 25px;
  line-height: 33px;
  margin: 30px 20px;
  text-align: center;
}
.praymain{
  padding: 0px 30px 30px 30px !important;
}
.inputfirst{
  width: 100% ;
}
.inputlast{
  width: 100%;
}
.maininput{
  display: flex;
  gap: 20px;
}
.innerinputfirst{
  padding: 20px !important;
  font-size: 18px !important;
}
.innerinputlast{
  padding: 20px !important;
  font-size: 18px !important;
}

.innerinputemail{
  padding: 20px !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 6px !important;
  height: auto !important;
  font-size: 18px !important;
}

.praybutton{
  padding: 20px 0 !important;
  font-size: 24px !important;
  background-color: #01A3D8 !important;
  border:  none !important;
}
.innerinputtext{
  font-size: 14px !important;
}
.closeicon{
  display: flex !important;
      justify-content: end;
      padding: 10px;
      cursor: pointer;
  
}
.closeicon svg {
  height: 27px;
  width: 27px;
}
.headingform{
  font-size: 28px;
}
.amountdonation{
  font-size: 28px;
}
.donatedescription{
  font-size: 20px;
  margin-top: 15px;
}

.youvideo{
  height: 100% !important;
}
.mainvideocontainer{
  height: 300px ;
}

.maincomment{
  background: #f5f5f5;
  padding: 30px 20px;
}

.innersection h2 span{
  font-size: 18px;
  font-weight: bold;
}

.innersection h2{
  font-size: 18px;
  font-weight: 500;
}
.innersection p{
  font-size: 20px;
  font-weight: 400;
}
.mainsectioncomment h1{
  font-size: 28px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .dontaion_row {
    width: 100%;
  }
  .selectAmount {
    padding: 12px 24px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .dontaion_row {
    width: 100%;
  }
  .smallscreeninput{
   
      display: flex;
  align-items: center;

  }
  .smallscreemdonate{
    display: none;
  }
  .selectedLabel{
    background-color: #ffffff;
    border: 1px solid #ffffff;
    background-color: #06a1d7;
    color:#ffffff;
    padding: 30px;
  }
  .selectedLabel span{
    font-size: 60px;
      line-height: 56px;
      font-weight: bold;
    
    color:#ffffff;
  
  }
  .thankcontainertextp p{
    text-align: center;
    font-size: 28px;
    line-height: 38px;

  }
 .thankcontainertext h3{
  font-size: 35px;
  line-height: 38px;
  text-align: center;
  margin-left: 0px;
  

 }
 .thankcontainertext br{
  display: none;
 }
 .donationcontainertext h1{
  font-size: 50px;
    line-height: 53px;
  
 }
 .donationcontainertext p{

    font-size: 25px;
    line-height: 60px;
 }
 .donationcontainertext{
  padding: 0px;
 }
    .donationsucesscontainer{
      height: 425px;
    }

 .informationtextp p{
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #ffffff;
}
 .informationnputmaincontainer{
  margin-bottom: 30px;
 }
 .informationscreenbutton button{
  font-size: 15px;
    width: 200px;
    height: 40px;
    border-radius: 47px;
    border: none;
    background-color: #ffffff;
    font-weight: bold;
    line-height: 13px;

 }
.donatethank h1{
  font-size: 38px;
  line-height: 40px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 56px;
  margin-left: 0px;

}
.donatethank br{
  display: none;
}
.inforcol{
  padding: 50px 0px 50px 0px;
 }
 .informationthank h1{
  margin: 20px 0px;
  margin-left: 0px;
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  color: #ffffff;
 }
 .paycol{
  padding: 50px 0;
 }
}

  @media (min-width:300px) and (max-width:767px) {
    .paymentForm{
      padding: 30px;
    }
    .paymentHead{
      margin-bottom: 20px;
    }
    .donateinputcontainer{ 
      padding: 10px 0 10px 18px;

    }
.paymentcontainer{
  display: block;
}

    .innerlabel{
      display: block;
    }
    .innerlabel p{
      margin-top: 10px;
    }
    .donateradio{
      display: flex;
      flex-direction:column;
      /* display: block; */
    }
    .donationbuttoncontainer{
      display: block;
      text-align: center;
    }
    .smallscreenabutton button{
    font-size: 18px;
    /* width: 200px;
    height: 40px; */
    border-radius: 47px;
    border: none;
    background-color: #ffffff;
    font-weight: bold;
    line-height: 28px;
    /* margin-top: 20px; */
    padding: 10px 0;

    }
    .smallscreena{
      margin-top: 20px;
    }
    .innerlabel span{
      font-size: 30px;
      line-height: 28px;
      font-weight: bold;
      color: #01a3d8;
    }
    .smallscreeninput{
      display: flex;
  align-items: center;
    }
    .selectedLabel{
      background-color: #ffffff;
      border: 1px solid #ffffff;
      background-color: #06a1d7;
      color:#ffffff;
      padding: 18px;
    }
    .selectedLabel span{
      font-size: 30px;
      line-height: 28px;
        font-weight: bold;
      
      color:#ffffff;
    
    }
    .donationcontainertext h1{
      font-size: 18px;
      line-height: 25px;
      
     }
     .donationcontainertext p{
    
      font-size: 13px;
      line-height: 23px;
  
     }
     .donationcontainertext{
      padding: 0px;
     }
     .donationsucesscontainer{
      height: 210px;
     }
     .donationthankcontainer{
      padding: 30px 0px;
     }
     .thankcontainertextp p{
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      font-weight: bold;
      text-align: center;
    }
   .thankcontainertext h3{
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin-left: 0px;
}
    
.donatethank h1{
  font-size: 38px;
  line-height: 40px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 56px;
  margin-left: 0px;
  padding: 0 20px;

}
.donatethank br{
  display: none;
}
   
   .thankcontainertext br{
    display: none;
   }
   .inforcol{
    padding: 50px 0px 50px 0px;
   }
   .paycol{
    padding: 50px 0;
   }
   .infoform{
    margin: 0 15px;
    padding: 20px 20px;
   }
   .informationbuttoncontainer button{
    font-size: 16px;
    width: 200px;
    height: 40px;
    border-radius: 47px;
    border: none;
    background-color: #ffffff;
    font-weight: bold;
    line-height: 28px;

   }
   .informationtextp p{
    font-size: 22px;
    line-height: 26px;
   }
   .informationthank h1{
  font-size: 30px;
  line-height: 34px;
  margin: 20px;
   }

   .centername{
    font-size: 20px;
    line-height: 24px;
   }
   .centerLocation{
    font-size: 18px;
    line-height: 22px;
   }
   .amountdonation{
    font-size: 18px;
   }
.headingform{
  font-size: 18px;
}
.donatedescription{
  font-size: 12px;
  
}
.paymentHead{
  font-size: 20px;
  line-height: 24px;
}
.donationbuttoncontainer{
  margin-top: 20px;
}
.donationpayment h3{
  font-size: 20px;
  line-height: 24px;
}
.donationlabel{
  padding: 15px;
}
.maindonation{
  padding: 0px;
}
.maincenterform{
  padding: 30px 30px;
}
.paymentformmain{
  padding: 0px;
}
.informationinput{
  height: 50px !important;
  padding: 10px !important;
}
.prayhead{
  font-size: 25px;
}
.praypara{
  font-size: 12px;
  line-height: 16px;
  margin: 15px 0 20px;
}
.innerinputtext{
  font-size: 12px !important;
}
.innerinputlast{
  padding: 15px !important;
  font-size: 12px !important;
}
.innerinputfirst{
  padding: 15px !important;
  font-size: 12px !important; 
}
.innerinputemail{
  padding: 15px !important;
  font-size: 12px !important; 
}

.praymain{
  padding: 0px 20px 30px 20px !important;
}
.praybutton{
  padding: 15px 0 !important;
  font-size: 18px !important;
}
.donationtable{
  margin-bottom: 0px
}

.mainsectioncomment{
  margin-top: 30px;
  padding: 0 30px;
}
.mainsectioncomment h1{
  font-size: 18px;
}

.innersection h2 span{
  font-size: 14px;
}

.innersection h2 {
  font-size: 14px;
}

.innersection p{
  font-size: 12px;
}

.maincomment{
  padding: 20px;
}

.customAmountInput{
  height: 50px;

}
.currency{
  display: flex;
   align-items: center;
}
}
