.about_banner {
    background-image: url('/public/aboutus/banner.svg');
    background-size: cover;
    color: white;
    background-position: center;
    background-color: #44574369;
    background-repeat: no-repeat;
    text-align: center;
    height: 95vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.banner_head {
    font-size: 41px;
    font-weight: 600;
    line-height: 51.25px;
    color: #FEEBC3;

}

.aboutmaincol {
    padding: 113px 0px 95px 0px;
}

.aboutus_heading {
    font-size: 50px;
    font-weight: bold;
    line-height: 52px;
    margin-bottom: 12px;

}

.aboutus_text {
    line-height: 24px;
    color: #484848;
    font-size: 20px;
    margin-bottom: 25px;

}

.aboutus_text span {
    line-height: 24px;
    color: #484848;
    font-size: 20px;
    margin-bottom: 25px;
    font-weight: bold;

}

.abouthead p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 10px;


}

.abouthead p span {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 10px;


}

.abouthead h4 {
    font-size: 35px;
    line-height: 52px;
    color: #01A3D8;
    font-weight: bold;

}

.abouthead h1 {
    font-size: 35px;
    color: #01A3D8;
    font-weight: bold;
    line-height: 52px;
    margin-bottom: 21px;

}

.aboutheadbutton button {
    background-color: #01A3D8;
    color: white;
    border-radius: 30px;
    font-size: 18px;

    margin-top: 50px;
    border: none;
    padding: 10px 32px;
}

.mainabout {
    background: #01A3D8;
    padding: 80px 0;
}

.mainabout h1 {
    color: #ffffff;
    font-size: 36px;
    font-weight: bold;
    line-height: 46px;

    /* margin-left: 124px; */

}



.aboutdonbutton button {
    background-color: #01A3D8;
    color: white;
    border-radius: 30px;
    font-size: 18px;
    margin-top: 50px;
    border: none;
    padding: 10px 32px;
    font-weight: bold;
}

.champabout h1 {
    font-size: 41px;
    color: #01A3D8;
    font-weight: bold;
    line-height: 52px;
}

.champtext h1 {
    font-size: 36px;
    color: #191919;
    font-weight: bold;
    line-height: 46px;
}

.champabout p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

}

.champtext p {
    font-size: 20px;
    line-height: 30px;
}

.champara p {
    font-size: 20px;
    line-height: 30px;
}

.beliefsection {
    padding-top: 60px;
}

.champaboutheading {
    font-size: 46px;
    color: #191919;
    font-weight: bold;
    line-height: 57px;

}



/* .churchesabout{
    width: 100%;
    background: #14586e;
} */
.churchesabout h1 {
    font-size: 70px;
    font-weight: bold;
    line-height: 69px;
    padding-top: 43px;
    text-align: center;

}

.churchestext {
    background-image: url("../../../public/churches.png");
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
}

.backcolor {
    background-color: #01A3D8;
    padding: 55px 0;

}

.churchtextp h1 {
    font-size: 50px;
    font-weight: bold;
    line-height: 52px;
    color: #ffffff;
    margin-bottom: 16px;

}

.churchtextp p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;

}

.churchtextp {
    padding: 160px 0px;
}

/* 
.abouttext{
    position: relative;
}
.abouttextheadp{
 
    position: absolute;
    top: 0;
    right: 0;
    padding: 290px 120px 0px 0px;
    color: white; 
    width: 50%; 
} */

.abouttextheadp h1{
    font-size: 35px;
    color: #ffffff;
    line-height: 45px;
    font-weight: bold;
    /* margin-bottom: 23px; */
    
    /* font-size: 50px;
    color: #ffffff;
    line-height: 50px;
    font-weight: bold;
    margin-bottom: 23px; */
}

.abouttextheadp p {
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
}



.aboutdatabut button {
    width: 156px;
    height: 48px;
    background-color: #01a3d8;
    color: white;
    border-radius: 30px;
    font-size: 18px;
    border: none;
    line-height: 24px;
    font-weight: bold;
}

.aboutdatabut {
    padding-left: 20px;

}

/* .abouttext h1{
    font-size: 55px;
    line-height: 78px;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    

}
.abouttext p{
    font-size: 30px;
    color: white;
    line-height: 39px;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    padding-left: 813px;
    padding-top: 19px;
    padding-bottom: 95px;
} */
.abouttextheadp {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0px 60px;
    background-color: black;
}

.imagecontainer {
    width: 50%;
}

.imagecontainer img {

    height: auto;
}

.customCards {
    border-radius: 0px;
    /* height: 378px ;
    margin-left: 34px; */

}

.cardConttit {
    padding: 21px 32px 21px 24px;
}

.aboutuscard {
    background: #01A3D8;
    padding: 105px 0px 145px 0px;



}

.cardsmain {
    display: flex;
    /* gap: 64px; */
}

.aboutrow {
    display: flex;
}

.aboutmainp p {
    font-size: 60px;
    line-height: 67px;
    /* line-height: 98px; */
    font-weight: bold;
    text-align: left;
    color: #ffffff;
}

.aboutimage {
    background-image: url("../../../public/Mask.png");
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    align-items: center;
    height: 392px;
    background-size: cover;
}

.emptext h4 {
    font-size: 25px;
    color: #ffffff;
    margin-bottom: 11px;
    line-height: 46px;
    font-weight: bold;
}

.emptext p {
    font-size: 16px;
    color: #ffffff;
    line-height: 20px;
}

.emptext p span {
    font-size: 16px;
    color: #ffffff;
    line-height: 20px;
    font-weight: bold;
}

.aboutstandbutton button {
    color: black;
    border-radius: 30px;
    font-size: 15px;
    margin: 40px 0;
    border: none;
    /* padding: 10px 32px; */
    line-height: 20px;
    width: 156px;
    height: 40px;
    font-weight: bold;

}

.aboutstandbutton {
    text-align: center;
}

.abouticon img {
    width: 55px;
    height: 55px;
}

.abouticon {
    display: inline-block;
    margin-bottom: 15px;

}

.aboutcardbutton button {
    background-color: #01A3D8;
    color: white;
    border-radius: 30px;
    font-size: 18px;
    border: none;
    /* padding: 10px 32px; */
    line-height: 21px;
    width: 160px;
    height: 48px;

}

.aboutcardbutton {
    text-align: center;
}

.cardtitle {
    font-weight: bold !important;
    font-size: 29px !important;
    line-height: 36px !important;

}

.cardtext {
    font-size: 16px !important;
    line-height: 24px !important;

}

.supportmain {
    background-color: #01A3D8;
    padding-top: 40px;

}

.supportcontainer {
    background-color: #000000;
    padding: 50px 104px 76px 96px;
}

.supportcontainer h1 {
    color: #ffffff;
}

.supportcontainer h4 {
    color: #ffffff;
    font-weight: 100;
}

.supportcontainer h2 {
    font-size: 40px;
    line-height: 42px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 32px;
}
.supportcontainer a {
  text-decoration: none !important;
}
.supportcontainer p{
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
}

.supportcontainer a {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #01A3D8;
    margin-top: 21px;
}

.helpabout {
    background-color: #01A3D8;
    text-align: center;
    padding: 88px 127px;
}

.helpabout h1 {
    font-size: 50px;
    line-height: 53px;
    font-weight: bold;
    color: #ffffff;
}

.content {
    background-image: url("/public/clouds.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}




.booktext h1 {
    color: #ffffff;
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 50px;

}



.booktext p {
    color: #000000;
    font-size: 25px;
    line-height: 30px;
    font-weight: 400;

}

.booktext p span {
    color: #000000;
    font-size: 25px;
    line-height: 30px;
    font-weight: bold;

}

.beliefsection p span {
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;

}

.booktext {
    padding: 50px 80px 0 0;
}

.bookimagecontainer {
    width: 100%;
    height: 606px;

}

.booklink {
    display: flex;
    align-items: center;
    margin-top: 40px;

}

.booklink a {
    margin-left: 56px;
    color: #333;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.bookbutton button {
    background-color: #01A3D8;
    color: white;
    border-radius: 30px;
    font-size: 15px;
    /* line-height: 64px; */
    margin-top: 110px;
    border: none;
    /* padding: 10px 32px; */
    width: 160px;
    height: 48px;

}
.aboutusimagecontainer img{
    width: 90%;
}
/* @media (max-width: 480px) {
    .aboutmainp p{
        font-size: 25px !important;
        line-height: 30px !important;
    }
    .belief_heading{
      font-size: 22px !important;
    } 
    .beliefsection h2{
        font-size: 25px !important;
      }
      .aboutus_heading{
        font-size: 25px !important;
      } 
      .abouthead h1{
        font-size: 25px !important;
        line-height: 30px !important;
      }
      .abouthead h4{
        font-size: 25px !important;
        line-height: 30px !important;
      }
    
      .unraveled_text{
        font-size: 25px !important;
      }
      .helpabout h1{
        font-size: 25px !important;
        line-height: 30px !important;
      }
  } */
@media (min-width:768px) and (max-width:1024px) {
    .aboutus_row {
        width: 100%;
    }

    .aboutmainp {
        padding: 0 75px;
    }

    .aboutmainp p {
        font-size: 40px;
        line-height: 40px;
    }

    .mainabout {
        padding: 64px;
    }

    .mainabout h1 {
        font-size: 30px;
        line-height: 38px;
    }

    .abouticon img {
        width: 40px;
        height: 40px
    }

    .emptext h4 {
        font-size: 18px;
        margin-bottom: 11px;
        line-height: 22px;
        margin-bottom: 0px;


    }

    .emptext p {
        font-size: 13px;
        line-height: 17px;
    }

    .emptext p span {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
    }

    .beliefsection h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .beliefsection p {
        font-size: 18px;
        line-height: 20px;

    }

    .beliefsection p span {
        font-size: 18px;
        line-height: 20px;
        font-weight: bold;

    }

    .champaboutheading {
        font-size: 36px;
        line-height: 40px;
    }

    .champtext h1 {
        font-size: 26px;
        line-height: 32px;
    }

    .champtext p {
        font-size: 18px;
        line-height: 20px;

    }

    .champaboutheading {
        font-size: 36px;

        line-height: 40px;
    }

    .champara p {
        font-size: 18px;
        line-height: 20px;

    }

    .beliefsection {
        padding: 70px;
    }

    .churchtextp h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .churchtextp p {
        font-size: 18px;
        line-height: 20px;
    }

    .churchtextp {
        padding: 70px;
    }

    .aboutus_heading {
        font-size: 36px;
        line-height: 40px;
        margin-top: 20px;
    }

    .aboutus_text {
        font-size: 15px;
        line-height: 20px;
    }

    .abouthead h1 {
        font-size: 30px;
        line-height: 27px;
    }

    .abouthead p {
        font-size: 15px;
        line-height: 20px;

    }

    .abouthead h4 {
        font-size: 28px;
        line-height: 33px;
    }
.abouthead{
    text-align: left;
}
    .aboutmaincol {
        padding: 70px;
    }

    .aboutuscard {
        padding: 70px;
    }

    .aboutheadbutton button {


        font-size: 15px;
        margin-top: 20px;

    }

    .aboutusimagecontainer {
       
        text-align: center !important;
    }
   .aboutusimagecontainer img {
       
    width: auto;
}
    .abouttextcontainer {
        text-align: center !important;
    }

    .cardtitle {

        font-size: 18px !important;
        line-height: 24px !important;
    }

    .cardtext {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .aboutcardbutton button {

        font-size: 12px;

        /* padding: 10px 32px; */
        line-height: 21px;
        width: 110px;
        height: 32px;
    }

    .cardConttit {
        padding: 12px;
    }

    .aboutrow {
        display: block;
    }

    .imagecontainer {
        width: 100%;
    }

    .abouttextheadp {
        width: 100%;
    }

    .abouttextheadp h1 {
        font-size: 32px;
        line-height: 40px;
    }

    .abouttextheadp p {
        font-size: 18px;
        line-height: 20px;
    }

    .supportcontainer h2 {
        font-size: 35px;
        line-height: 35px;

        margin-top: 28px;

    }

    .supportcontainer p {
        font-size: 13px;
        line-height: 21px;

    }

    .supportcontainer a {

        font-size: 13px;
        line-height: 30px;

        margin-top: 20px;
    }

    .supportcontainer {
        padding: 70px;
    }

    .helpabout h1 {
        font-size: 32px;
        line-height: 40px;
    }

    .helpabout {
        padding: 70px;
    }

    .bookimagecontainer {
        width: 100%;
        height: auto;
    }

    .booktext h1 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;


    }

    .booktext p {

        font-size: 16px;
        line-height: 18px;
        font-weight: 400;


    }

    .booktext p span {

        font-size: 16px;
        line-height: 18px;
        font-weight: bold;


    }

    .booklink {

        margin-top: 20px;
    }

    .bookbutton button {

        font-size: 13px;

        width: 120px;
        height: 36px;
    }

    .booklink a {
        font-size: 13px;
        margin-left: 20px;
    }

    .booktext {
        padding: 26px 20px 20px 0;
        ;
    }

    .abouttextheadp {
        padding: 70px;
    }

   
}

@media (min-width:300px) and (max-width:767px) {

    .aboutmainp {
        padding:25px;
    }
 .aboutimage{
    height: auto;
 }
    .aboutmainp p {
        font-size: 20px;
        line-height: 24px;

    }

    .aboutdonbutton button {


        font-size: 12px;
        margin-top: 20px;

        padding: 7px 20px;
    }

    .mainabout {
     
        padding: 20px;
    }

    .mainabout h1 {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
    }

    .aboutstandbutton {
        display: none;
        text-align: left;
    }

    .aboutstandbutton button {

        font-size: 13px;
        margin: 30px 0;

        line-height: 20px;
        width: 140px;
        height: 35px;
    }

    .abouticon img {
        width: 35px;
        height: 35px;
    }

    .emptext h1 {

        margin-bottom: 10px;
        font-size: 25px;
        line-height: 35px;

    }

    .emptext p {
        font-size: 12px;
        line-height: 18px;

    }

    .emptext p span {
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;

    }

    .beliefsection {
        padding: 20px;
    }

    .beliefsection h1 {
        font-size: 30px;
        line-height: 30px;
    }

    .beliefsection p {
        font-size: 12px;
        line-height: 18px;
    }

    .champaboutheading {
        color: #191919;
        font-size: 20px;
        line-height: 24px;
    }

    .champtext h1 {
        font-size: 18px;
        line-height: 22px;
    }

    .champtext p {
        font-size: 12px;
        line-height: 18px;
    }

    /* .champaboutheading {
        font-size: 30px;
        line-height: 30px;
    } */

    .champaboutheading p {
        font-size: 15px;
        line-height: 19px;

    }

    .churchtextp {
        padding: 20px;
    }

    .churchtextp h1 {
        font-size: 25px;
        line-height: 35px;
    }

    .churchtextp p {
        font-size: 12px;
        line-height: 18px;
    }

    .aboutmaincol {
        padding: 20px;

    }

    .aboutus_heading {
        font-size: 20px;
        line-height: 24px;
        margin-top: 15px;
    }

    .aboutus_text {
        text-align: left;
        font-size: 15px;
        line-height: 19px;
    }
    .abouttextcontainer {
        text-align: center !important;
    }


    .abouthead {
        text-align: left;
        font-size: 30px;
        line-height: 30px;
    }

    .abouthead p {
        font-size: 12px;
        line-height: 18px;
    }

    .abouthead h4 {
        font-size: 20px;
        line-height: 24px;

    }
    .aboutheadbutton  button{
        font-size: 12px;
        margin-top: 20px;
    
    }

    .customCards {
        margin-top: 20px;
    }

    .aboutuscard {
        padding: 20px;
    }

    .abouttextheadp {
        width: 100%;
        padding: 20px;
    }

    .abouttextheadp h1 {
        font-size: 20px !important;
        line-height: 24px !important;
    }

    .abouttextheadp p {
        font-size: 15px;
        line-height: 19px;
    }

    .supportcontainer {
        padding: 20px;
    }

   
.supportcontainer h2{
    font-size: 20px;
    line-height: 24px;
}
    .supportcontainer p {
        font-size: 12px;
        line-height: 18px;
    }
.supportcontainer a{
    line-height: 24px;
}
    .aboutrow {
        display: block;
    }

    .imagecontainer {
       
        width: 100%;
    }

    .bookimagecontainer {
        width: 100%;
        height: auto;
    }
    .helpabout {
        padding: 20px;
    }

    .helpabout h1 {
        font-size: 20px;
        line-height: 24px;

    }

    .booktext {
        padding: 0 20px 20px 20px;
        margin-top: -50px;
    }

    .booktext h1 {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 20px;
        color: #191919;
    }

    .booktext p {
        font-size: 12px;
        line-height: 18px;
    }

    
    .booktext p span {
        font-size: 12px;
        line-height: 18px;
    }

    .booklink a{
        font-size: 12px;
        line-height: 18px;
        margin-left: 20px;
    }
    .bookbutton button{
        font-size: 12px;
        height: 36px;
        width: 120px;
    }
    .aboutusimagecontainer img {
   
        width: auto;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1250px) {
    .booktext {
        padding: 50px 0px 0px 0px !important;
        /* padding-block: 40px; */
        /* font-size: 37px !important; */
    }
    .booktext h1 {
        color: #ffffff;
        font-size: 35px;
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 41px;
    
    }
    
}

@media(min-width: 1025px) and (max-width:1350px) {
   .abouttextheadp h1{
    font-size: 24px;
    color: #ffffff;
    line-height: 27px;
    font-weight: bold;
   }
    
}