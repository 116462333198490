.dashboard_main {
  background-color: #445743;
  /* width: 100%; */
}

.dashboard_row {
  width: 100%;
  margin: auto;
  color: black
  /* margin-left: 50px; */
  /* color: white; */
}
.homesocialicons{
  display: flex;
  justify-content: end;
 
  gap: 10px;
}

.bannerHeading {
  font-size: 40px;
  font-weight: 600;
  line-height: 51.25px;
}

.box_image {
  width: 90%;
}

.bannerHeading2 {
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  color: black;
  margin-bottom: 20px;
  /* color: black;

  /* margin-left: 43px;
  margin-top:30px */
  /* color: black; */
}

.bannerHeading2 span {
  font-size: 19px;
  font-weight: bold;
  line-height: 28px;
  color: black;
  margin-bottom: 20px;
  /* color: black;

  /* margin-left: 43px;
  margin-top:30px */
  /* color: black; */
}

.bancol {

  padding-left: 0px;
  padding-right: 60px;
}

.videoContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .videoContainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;  
} */

.overlayContent {
  position: absolute;
  /* top: 35%; */
  /* left: 50%; */
  /* bottom: 50%; */
  /* right: 50%; */
  /* transform: translate(-50%, -50%); */
  color: white;
  text-align: center;
  /* min-width: 100%; */
  /* min-height: 100%; */
}

.overlayContent h1 {
  /* font-size: 36px; */
  font-size: 27px;
  line-height: 58px;
  font-weight: 400;
  /* font-family: 'aptos'; */

  /* Adjust margin as needed */
}


.overlayContent h2 {
  /* font-size: 80px; */
  font-size: 72px;
  /* Responsive font size */
  line-height: 76px;
  font-weight: bold;
  padding: 15px 0;
  /* Adjust margin as needed */
}

.overlayContent h2 span {
  font-size: 72px;
  line-height: 76px;
  font-weight: bold;
  color: #01a3d8;
}

.overlayContent a {
  color: white;
  /* text-decoration: underline; */
  font-size: 16px;
  line-height: 24px;
  font-weight: lighter;

  /* Optional: underline the link */
}

/* .topLeftHeading h1, .topLeftHeading h2 {
  margin: 10px 0; 
} */

/* .topLeftHeading a {
  color:white;
  text-decoration: underline; 
} */

/* home.module.css */
.imageContainer {
  background-image: url("../../../public/female.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* .content {
  position: absolute;
  top: 0px;

} */

.actionAndCardsContainer {
  display: flex;
  align-items: center;
  padding: 115px 0px 38px 120px;
  /* Aligns items to the top */

}

.textSection {
  /* padding-top: 24px; */
  margin-left: 25%;
  color:#ffffff;
  /* background-color: #ffff; */
    /* width: 100%; */
    /* padding: 50px; */
    /* opacity: 0.7; */
}

.textSection h1 {
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
}

.actionbutton button {
  /* width: 156px;
  height: 40px; */
  background-color: #01a3d8;
  color: white;
  border-radius: 30px;
  /* font-size: 18px; */
  margin-top: 110px;
  border: none;
  font-size: 20px;
  margin-top: 110px;
  border: none;
  padding: 14px 60px;

  /* line-height: 24px; */
}

.maincentercontainer {
  background-color: #ffffff;
  /* background-color: #06A1D7; */
  padding: 65px 0px 70px 0
}

.misionbutton button {
  background-color: #01a3d8;
  color: white;
  border-radius: 30px;
  font-size: 18px;
  margin-top: 30px;
  border: 1px solid #ffffff;
  padding: 10px 32px;
}

.donatebutton button {
  padding: 10px 40px;
  background-color: #01a3d8;
  color: white;
  border-radius: 30px;
  font-size: 15px;
  margin-top: 25px;
  font-weight: bold;
  border: none;
  /* border: 1px solid #ffffff; */
  line-height: 24px;
}

/* .bannerbutton {
  bottom: 0;
position: absolute;
} */
.relativePos {
  position: relative;
}

.bannerbutton button {
  padding: 10px 40px;
  background-color: #01a3d8;
  color: white;
  border-radius: 30px;
  font-size: 15px;
  margin-top: 26px;
  font-weight: bold;
  border: none;
  /* border: 1px solid #ffffff; */
  line-height: 1.5;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 385px;
  margin-left: 82px;
}

.actiona4 {
  font-size: 8px;
  font-weight: 400;
  color: #333333;
  text-decoration: none;
}

.customCards {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px 30px;
  background-color: #f8f9fa;
  border-radius: 0% !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.customCards2 {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px 10px 30px;
  background-color: #f8f9fa;
  border-radius: 0% !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* padding: 10px; */
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* padding: 15px;
 padding-bottom: 25px; */
}

.cardtitle {
  font-size: 36px !important;
  line-height: 46px !important;
  font-weight: bold !important;
}

.cardtitle2 {
  font-size: 29px !important;
  line-height: 37px !important;
  font-weight: bold !important;
}

.cardtext {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}
.singletext{
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}

.acardtext {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  margin-left: 15px;
}

.cardbody {
  padding-top: 0px !important;
}

.actioncardtext {
  display: flex;
  margin-bottom: 10px;
}

.actiona {
  font-size: 10px;
  color: #333333;
}

.cardtitlehead {
  font-size: 26px;
  font-weight: bold;
}

.imcontainer {
  padding: 25px 0px;
  background-color: #01a3d8;
  position: relative;
  /* width: 100%; */

  /* Adjust height if needed */
}

.imtextContent {
  /* position: absolute;
  top: 0;
  right: 0; */
  /* Adjust width as needed */
}
.missionmain{
  display: flex;
  width: 50%;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

}
.containerOne {

  padding:  0px 120px 0px 0px;
  /* Adjust padding as needed */
  color: white;
  /* Adjust text color for visibility */
  /* Optional: semi-transparent background for better readability */
  /* width: 50%; */
}

.containerTwo {
  padding: 0px 120px 0px 0px;
  /* Adjust padding as needed */
  color: white;
  /* Adjust text color for visibility */
  /* Optional: semi-transparent background for better readability */
  /* width: 50%; */
}

.containerThree {
  padding: 0px 120px 0px 0px;
  /* Adjust padding as needed */
  color: white;
  /* Adjust text color for visibility */
  /* Optional: semi-transparent background for better readability */
  /* width: 50%; */
}

/* .imtextContent p {
 font-size: 18px;
 line-height: 28px;
 font-weight:400; 
}

.imtextContent h1 {
  font-size: 32px;
  line-height: 44px;
  font-weight: bold;
 }
  */

.socialIcons {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 20px;
}

.socialIcons img {
  /* width: 32px;
  height: 32px; */
  cursor: pointer;
}

.navigationButtons {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  /* So that clicks only affect buttons, not the image */
}

.previousButton,
.nextButton {
  pointer-events: all;
  /* Ensure the buttons are clickable */
  /* width: 50px; 
  height: 50px; */
  cursor: pointer;
}

.previousButton {
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  margin-left: 10px;
}

.nextButton {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  margin-right: 10px;
}

.headingone {
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
  margin-bottom: 20px;
}

.headingtwo {
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
}

.headingthree {
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
}

.headingfour {
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
  margin-bottom: 25px;
}

.paragraphone {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.paragraphtwo {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom:20px;
}

.paragraphthree {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.paragraphfour {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.bannerHeading3 {
  font-size: 54px;
  font-weight: bold;
  line-height: 52px;
  margin-bottom: 20px;
  /* color: black; */
  color: #01a3d8;
}

.centerheading h1 {
  font-size: 46px;
  font-weight: bold;
  line-height: 78px;
  /* margin-bottom: 30px; */
  /* color: black; */
  /* padding-top: 30px;
  margin-left: 43px; */
  color: #191919;
}

.centerheading p {
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  color:#191919;

}

.centerheading2 p {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #191919;
}

.banner_description {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
}

.abort_main {
  padding: 130px 0;
  /* background-color: #edf4f1; */
  /* background-color: #ffffff; */
}

/* .centerBox {
  padding: 60px 55px;

} */
.centerbox {
  position: relative;
  /* margin-top: 65px; */
}

.helpbutton button {
  background-color: #01a3d8;
  border: 1px solid #01a3d8;
  border-radius: 42px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 21px;
  padding: 16px 55px;


}

.helpbutton {
  text-align: center;

  /* text-align: right; */
  /* margin-bottom: 45px; */
}

/* .centercol {
  padding-right: 50px;
} */

.maincenterbox{
  border-radius: 15px;
   padding: 50px 50px 0px 50px;

  margin-bottom: 50px;
  background-color: rgba(255, 255, 255, 0.7);

}

.centerHeading {
  font-size: 32px;
  font-weight: 600;
  color: black;
  text-align: center;
}
.fixedImage {
  width: 294px; /* Fixed width */
  height: 163px; /* Fixed height */
  
}

/* .fixedvideo {
  width: 294px !important; 
  height: 163px !important; 
   padding-top:15px; 
   padding-bottom: 9px
} */


.heading h1 {
  font-size: 61px;
  font-weight: bold;
  line-height: 78px;
  color: White;
  text-align: center;
}

.heading p {
  font-size: 20px;
  font-weight: 400;
  color: White;
  text-align: center;
  line-height: 30px;
  padding-bottom: 20px;
}

.centerCard {
  box-shadow: 4px 5px 21px 0px #00000012;
  padding: 23px;
  background: #a8e5f1;
  color: black;
  border-radius: 15px;
  /* padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px; */
}

.box {
  /* padding-top: 70px; */
  /* padding-bottom: 50px; */
  /* margin-left: 29px; */
  /* padding: 10px; */
  /* margin-right: 21px; */
  /* box-shadow: 4px 5px 21px 0px #00000012;
  padding: 10px;
  background: #06a1d7;
  border-radius: 4px;
  margin: 20px auto; */
}

/* .browsebutton {
  color: "black";
  background: rgb(255 255 255);
  padding: "10px 50px";
  font-size: "16px";
  font-weight: "normal";
} */

.browsebutton {
  color: black !important;
  /* Removed quotes */
  background: rgb(255, 255, 255) !important;
  /* Fixed syntax */
  padding: 10px 50px !important;
  /* Removed quotes */
  font-size: 16px !important;
  /* Removed quotes */
  font-weight: normal !important;
  /* Removed quotes */
}

.browsebutton:hover {
  background-color: #006f96 !important;
  /* Darker color on hover */
  color: white !important;
  border: 1px solid #006f96 !important;
}

.undertext {
  /* background-color: #445743 !important; */
  /* color: white !important; */
  border-radius: 40px !important;
  --bs-progress-height: 0.6rem !important;
}

.undertext div {
  background-color: #445743 !important;
  border-radius: 40px !important;
}

.lightbg {
  border-radius: 20px;
  background-color: #f1f1f1;
  padding: 4px 10px;
  margin-right: 8px;
  font-size: 14px;
}

.yelbtn {
  font-size: 19px;
}

.location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: gray;
}

.cardheading {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 35px;
  /* Remove any default margin */
  padding: 0;
  font-size: 54px;
  line-height: 67px;
  font-weight: bold;
}

.cardtextt h1 {
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  color: #191919;
}

.cardtextt {
  margin: 0;
}

.standcontainer {
  position: relative;
}

.standtext {
  position: absolute;
  bottom: 0;
  /* Aligns text to the bottom of the container */
  /* Corrects horizontal centering */
  text-align: center;
  /* Centers text within the text container */
  width: 100%;
  /* Ensure text container spans the full width */
  padding-bottom: 63px;
  /* Optional padding */
  color: white;
  /* Text color to contrast with the image */
  /* Optional background to improve text visibility */

}

.standtext h1 {
  font-size: 74px;
  line-height: 96px;
  font-weight: bold;
  margin-bottom: 13px;
}

.centerbrowse {
  cursor: pointer;
}

.standtext h2 {
  font-size: 30px;
  line-height: 20px;
  font-weight: bold;
}

.standtext p {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.standtext  a {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #ffffff;
  /* text-decoration: none  !important; */
}
/* .customCard:hover {
  transform: translateY(-10px);
} */

.customCard .cardTitle {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}

.customCard .cardText {
  text-align: center;
  font-size: 1rem;
  color: #555;
}

.bookcontainer {
  position: relative;
}

.cloudImageContainer {
  position: relative;
}

/* .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
} */

.bookimage img {
  margin-left: -42px;
}

.booktext {
  margin-left: -100px;
  margin-right: 143px;
}

.booktext h1 {
  color: #fff;
  font-size: 57px;
  line-height: 60px;
  font-weight: bold;
  margin-top: 15px;
}

.booktext h4 {
  color: #333;
  font-size: 37px;
  font-weight: bold;
  line-height: 49px;
  margin-top: 30px;
}

.booktext p {
  color: #333;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

.booklink {
  display: flex;
  align-items: center;
}

.booklink a {
  margin-left: 56px;
  color: #333;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

/* .movementconatiner {
  background-color: #01a3d8;
  position: relative;
  width: 100%;
} */
/* .imagemov{
padding-top: 81px;
} */
.movecont2 {
  background-image: url("../../../public/movement.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.movetextConatiner {

  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  padding: 55px 124px 70px 124px; */
}

.headingmove {
  color: #01a3d8;
}

.movetextConatiner {
  padding: 55px 0px 70px 0px
}

.headingmove h1 {
  font-size: 61px;
  line-height: 65px;
  font-weight: bold;
}

.headingmove h2 {
  font-size: 61px;
  line-height: 65px;
  font-weight: bold;
}

/* .paramove{
  padding-top: 20px;
} */
.paramove p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: white;
  text-align: center;
  margin: 22px 53px;
}

/* .paramove a {
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 72px;
} */
.movea a {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.paramove h2 {
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  text-decoration: none;
  color: #01a3d8;
  margin-top: 72px;
  text-align: center;
}

.movedonate h1 {
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  color: #01a3d8;
}

.movedonate {
  margin-bottom: 0px;
}

.maindib {

  padding: 115px 0 145px 0;
}
.dataimagecontainer{
  background-image: url("../../../public/movement.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.movedonate p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: white;
}

.movebutton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.movebutton button {
  width: 156px;
  height: 40px;
  background-color: #01a3d8;
  color: white;
  border-radius: 30px;
  font-size: 15px;
  margin-top: 45px;
  border: none;
  line-height: 24px;
}

.movetext {
  padding-top: 57px;
  margin: 0px;
}

.dataheading h1 {
  font-size: 40px;
  font-weight: bold;
  line-height: 44px;
  /* margin-right: 87px; */
  color: #ffffff;

}
.movea{
  margin-top: 50px;
}


.datapara {
  padding-top: 30px;
}

.datatext {
  padding-top: 50px;
}

.datapara p {
  font-size: 34px;
  font-weight: 400;
  line-height: 36px;
}

.datatext p {
  font-size: 33px;
  font-weight: bold;
  line-height: 38px;
  color: #01a3d8;
}
.movesocial{
  display: flex;
  gap: 20px;


}
.datacontainer {
  padding-top: 75px;
  padding-left: 100px;
  padding-bottom: 133px;
}

.newsdata {
  display: flex;
  justify-content: space-between;

  padding-top: 65px;
  padding-bottom: 40px;

  align-items: center;
}

.newshead h3 {
  font-size: 25px;
  font-weight: bold;
  line-height: 27px;
  color: #191919;
  text-transform: uppercase;
  padding-right: 50px;
}

.newsemail {
  display: flex;
  justify-content: center;
  /* Center the input box within the container */
  /* Optional: adjust the spacing around the input box */
}

/* .inputtype {
  display: flex;
  align-items: center;
} */

input[type="email"] {
  width: 100%;
  height: 48px;
  font-size: 15px;
  /* Set the font size */
  ;
  /* Adjust padding for better spacing */
  border: 1px solid #ccc;
  /* Border color and width */
  border-radius: 30px;
  /* Optional: rounded corners */
}

.inplace {
  padding-inline: 24px;
}

/* .inplace::placeholder{
padding-left: 24px;
} */
.navmaindb {
  background-color: #dedede;
}

.error {
  color: red;
  padding-left: 10px;
}

.databut button {
  width: 100%;
  height: 48px;
  background-color: #01a3d8;
  color: white;
  border-radius: 30px;
  font-size: 18px;
  border: none;
  line-height: 24px;
}

.databut {
  /* padding-left: 20px; */

}


.card_image_banner {
  height: 213px !important;
}

.contentPara {
  font-size: 25px;
  line-height: 30px;
  /* font-family: 'Times New Roman', Times, serif; */
  text-align: justify;
  margin-top: 30px;
}

.mainrow{
  display: flex;
  padding-top: 25px;
    background: #01A3d8;
}

.neighbourimagecontainer{
  
    width: 50%;

}

.neighbourcontainer{

  display: flex;
  align-items: center;
  width: 50%;
  padding: 0px 60px;
  background-color: black;
}
.neighbourtext h1{

    font-size: 40px;
    color: #ffffff;
    line-height: 45px;
    font-weight: bold;

}
.neighbourtext p{
  color: #ffffff;
  font-size: 25px;
  line-height: 35px;
}
@media (max-width:1024px) {
  .dashboard_row {
    width: 100%;
  }
.fixedvideo{
  width: 100% !important;
  height: 100% !important;
}
  .bannerHeading {
    font-size: 20px;
    line-height: 30px;
  }

  .overlayContent a {
    display: block;
  }
.homesocialicons{
  display: flex;
  justify-content: center;
}
  .overlayContent h2 span {
    font-size: 67px;
    line-height: 60px;
    color: #ffffff;
  }

  .overlayContent {
    text-align: left;
  }

  .overlayContent h1 {
    font-size: 20px;
    line-height: 25px;
  }
  .fixedImage{
    width:100%
   
  
  }
  .overlayContent h2 {
    font-size: 40px;
    line-height: 48px;
    padding: 0px;

  }
  .overlayContent h2 span {
    font-size: 40px;
    line-height: 48px;
    padding: 0px;
  }

  .contentPara{
    margin-top: 0px;
   font-size: 15px;
    line-height: 18px;
  }
  .contentvideo {
    padding:0px
  }

  .bancol {

    padding: 50px 0px;
  }

  .abortimg img {
    width: 100% !important;

  }

  .abortimg {
    width: auto !important;
  }

  .actionAndCardsContainer {
    padding: 115px 0px 38px 65px;
  }

  .actionbutton button {
    margin-top: 38px;
  }

  .cardsContainer {

    width: 350px;
    margin: 0px
  }

  .centerbox {
    margin-top: 0px;
  }

  .textSection h1 {
    font-size: 25px;
    line-height: 30px;

    font-weight: 400;
  }

  .containerOne {
    padding: 0px 80px 0px 0px;
  }
 
.missionmain{
  display: flex;
  width: 80%;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;


}
  .headingone {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 5px;
  }

  .paragraphone {
    font-size: 16px;
    line-height: 28px;
  }

  .headingtwo {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 5px;
  }

  .headingthree {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 5px;
  }

  .paragraphthree {
    font-size: 16px;
    line-height: 28px;
  }

  .headingfour {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 5px;
  }

  .paragraphfour {
    font-size: 15px;
    line-height: 20px;

  }

  .containerTwo {
    padding: 0px 120px 0px 0px;
    /* width: 80%; */
  }

  .containerThree {
    padding: 0px 120px 0px 0px;
/* 
    width: 80%; */
  }

  .paragraphtwo {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  .misionbutton button {
    margin-top: 20px;
  }

  .donatebutton button {
    display: none;
  }

  .cardheading {
    margin-bottom: 30px;
    padding: 0;
    font-size: 24px;
    line-height: 25px;
    font-weight: bold;
  }

  .cardtextt h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .standtext h1 {
    font-size: 40px;
    line-height: 70px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .abort_main {
    padding: 20px;
    /* Adjust padding for smaller screens */
  }

  .bandata {
    width: auto !important;
    /* Ensure this class doesn't inherit width from .col-md-6 */

  }

  .helpbutton {
    margin-top: 30px;
    text-align: center;
    margin-right: 0px;
  }



  .centercol {
    padding-right: 0px;
  }

  .headingmove h1 {
    font-size: 48px;
    line-height: 60px;

  }

  .movedonate h1 {
    font-size: 24px;
    line-height: 46px;
  }

  .movedonate p {
    font-size: 18px;
    line-height: 24px;
  }

  .paramove h3 {
    font-size: 36px;
    line-height: 46px;
    margin-top: 30px;
  }

  .paramove p {
    font-size: 20px;
    line-height: 30px;

  }

  .movebutton button {

    margin-top: 30px;

  }

  .dataheading h1 {
    font-size: 28px;
    font-weight: bold;
    line-height: 37px;
  }

  .datatext p {
    font-size: 25px;
    font-weight: bold;
    line-height: 37px;
  }

  .maindib {
    padding: 60px 0px;
  }

  .newshead h1 {
    font-size: 25px;
    margin-bottom: 20px;
    line-height: 27px;


  }

  input[type="email"] {
    width: 100%;
    font-size: 15px;


  }

  .databut button {
    width: 100%;

  }

  .newsdata {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  /* 
  .bancol {
    padding-left: 10px;
    padding-right: 10px;
  }

  .bannerHeading {
    font-size: 28px; 
    line-height: 36px;
  }

  .bannerHeading2 p {
    font-size: 14px; 
    line-height: 22px;
  } */

  /* .box img {
    width: 100%;
  } */

  .donatebutton button {
    font-size: 14px;
    
    padding: 8px 16px;
  }

  .centerHeading {
    font-size: 20px;
  }

  .serchInput {
    width: 98% !important;
  }

  .serchInputsec {
    font-size: 14px !important;
  }

  .serchInputbtn {
    font-size: 14px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .mainrow{
    display: block;
  }
  .neighbourimagecontainer{
    width: 100%;
  }
    .neighbourcontainer{
      width: 100%;
      padding: 60px;
    }
  
   
}

/* @media (max-width: 768px) {
  .box_image{
    width: 100%;
  }
  .bannerHeading3 h1 {
    font-size: 2rem;
  }

  .bannerHeading2 p,
  .bannerHeading2 p span {
    font-size: 1rem;
  }

  .bannerbutton button {
    font-size: 0.875rem;
  }
} */


@media (min-width:1025px) and (max-width:1250px) {
  .containerOne {
    padding: 116px 105px 0px 0px;
  }

  .headingone {
    font-size: 36px;
    ;
    line-height: 46px;
    margin-bottom: 20px;

  }

  .paragraphone {
    font-size: 18px;
    line-height: 28px;
  }

  .misionbutton button {
    margin-top: 25px;
    font-size: 17px;
  }

  .containerTwo {
    padding: 100px 60px 0px 0px;
  }

  .headingtwo {
    font-size: 30px;
    line-height: 39px;
  }

  .paragraphtwo {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 35px;
  }

  .headingthree {
    font-size: 30px;
    line-height: 39px;
  }

  .paragraphthree {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 35px;
  }

  .containerThree {
    padding: 150px 110px 0px 0px;
  }

  .headingfour {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;

  }

  .paragraphfour {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }


}


@media (min-width:300px) and (max-width:767px) {

  .contentvideo {
    padding: 0px;
  }

  /* .overlayContent {
    width: 70%;
  } */
  .overlayContent a {
    display: block;
  }
  .fixedvideo{
    width: 100% !important;
    height: 100% !important;
  }
  .overlayContent h1 {
    font-size: 8px;
    line-height: 8px;
  }

  .overlayContent h2 {
    font-size: 18px;
    line-height: 21px;
    padding: 0px;
    text-align: center;
  }

  .overlayContent h2 span {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }
  .homesocialicons img{
    width: 15px;
    height: 15px;
  }
.contentPara{
  
    font-size: 8px;
    line-height: 10px;
    font-family: 'Times New Roman', Times, serif;
    text-align: justify;
    margin-bottom: 0px;
    margin-top: 0px;
}
  .abort_main {
    padding: 20px 20px 0px 20px;
  }

  .bancol {
    padding: 35px 0px;
  }
  .donatebutton button {
    display: none;
   
  }

  .homesocialicons{
    display: flex;
    gap: 5px;
    justify-content: center;
  }

  .donatebutton{
    display: none;
  }
  .bannerHeading3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .playcircle{
    width: 45px;
  }

  .bannerHeading2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 15px;
  }

  .bannerHeading2 span {
    font-size: 15px;
    line-height: 19px;
    margin-top: 20px;
  }

  .actionAndCardsContainer {
    display: block;
    padding: 45px 20px;
  }

  .textSection h1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

  }
  .textSection{
    text-align: center;
    margin-left: 0px;
  }

  .textSection br {
    display: none;
  }
.cardtitle{
  font-size: 20px !important;
  line-height: 24px !important;
}
.cardtitle2{
font-size: 20px !important;
line-height: 24px !important;
}
.cardtext{
  font-size: 12px !important;
  line-height: 18px !important;
}

.cardtitlehead{
  font-size: 25px !important;
}
.acardtext{
  font-size: 12px !important;
  line-height: 18px !important;
}
  .cardsContainer {
    margin-left: 0px;
  }

  .customCards {
    width: 100%;
    margin-top: 20px;
  }

  .customCards2 {
    width: 100%;
    margin-top: 20px;
  }

  .actionbutton button {
    border: none;
    font-size: 13px;
    padding: 6px 35px;
    margin-top: 10px;
  }

  .bannerbutton button {
    margin-top: 0px;
    font-size: 11px;
    padding: 4px  15px;
  }

  .imcontainer {
    padding: 25px 0px;
  }

  .containerOne {
    padding: 41px 20px;
    /* width: 100%; */
  }

  .headingone {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 5px;
  }

  .paragraphone {
  font-size: 10px;
  line-height: 14px;
  }

  .misionbutton button {
    padding: 4px 15px;
    margin-top: 0px;
    font-size: 11px;
  }

  .containerTwo {
    /* width: 100%; */
    padding: 41px 20px;
  }

  .headingtwo {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
  }

  .paragraphtwo {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .headingthree {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
  }

  .paragraphthree {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .containerThree {
    padding: 41px 20px;
    width: 100%;
  }

  .paragraphfour {
    font-size: 8px;
    line-height: 10px;

  }

  .headingfour {
    font-size: 12px;
    line-height: 15px;

  }

  .previousButton {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }


  .nextButton {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
  .standtext {
    padding-bottom: 8px;

  }

  .standtext h1 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .standtext p {
    font-size: 11px;
    line-height: 2px;
    font-weight: 400;
  }

  .maincentercontainer {
    padding: 50px 20px 50px 20px;
  }

  .cardheading{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .cardtextt h1{
    font-size: 18px;
    line-height: 22px;
  }
  .centerheading h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }

  .centerheading p{
    font-size: 18px;

    line-height: 22px;
  }

  .centerheading2 p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
.fixedImage{
 width: 100%;
}

.maincenterbox{
  padding: 20px;

}
  .helpbutton {
   margin-top: 0px;
    margin-bottom: 0px;

    text-align: center;
  }

  .helpbutton button {
    padding: 10px 15px;

    font-size: 15px;
  }

  .movetextConatiner {
    padding: 50px 20px;
  }

  .headingmove h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .cardblock {
    display: flex;
    justify-content: center;
  }

  .movedonate {
    margin-bottom: 10px;
  }

  .movedonate h1 {
    font-size: 18px;
    line-height: 22px;
  }
.missionmain{
  display: flex;
  width: 100%;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  justify-content: center;



}
  .movedonate p {
    font-size: 12px;
    line-height: 18px;

  }

  .paramove h2 {
    font-size: 20px;
    line-height: 24px;
    margin-top: 30px
  }

  .paramove p {
    margin: 20px 0;
    font-size: 12px;
    line-height: 18px;
  }

  .movea a {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
.movea{
  margin: 0px;
}
  .movetext {
    padding-top: 20px;
  }

  .maindib {
    padding: 50px 20px;
  }

  .centerbox {
    margin-top: 0px;
  }

  .dataheading h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
  }

  .datatext p {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }

  .datatext {
    padding-top: 30px;
  }

  .newsdata {
    padding: 50px 20px;
  }

  .newshead h3 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-right: 0;
  }

  input[type="email"] {
    font-size: 12px;
    height: 40PX;
    width: 100%;
  }

  .databut button {
    width: 100%;
    height: 40px;
    font-size: 11px;
    padding: 0px;
  }

  .databut {
    padding-left: 0px;
  }

  .cardmain {
    padding: 0px 20px;
  }

  .actiona4 {
    font-size: 10px;
  }

  .newshead {
    padding: 0px;
  }
.contentvideo{
  padding: 0px 20px;
}

.mainrow{
  display: block;
}
.neighbourimagecontainer{
  width: 100%;
}
  .neighbourcontainer{
    width: 100%;
  }

  .neighbourtext h1{
    font-size: 20px;
    color: #ffffff;
    line-height: 24px;
    font-weight: bold;
  }
  .neighbourtext p{
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;

  }
  .neighbourcontainer {
    /* justify-content: center; */
    /* display: flex; */
    /* align-items: center; */
  
    padding: 20px;
    background-color: black;
}
}
/* @media (max-width: 480px) {
  .contentvideo {
    font-size: 30px !important;
  }
  .bannerHeading3{
    font-size: 25px !important;
  }
  .cardtitle{
    font-size: 25px !important;
  }
  .cardtitle2{
    font-size: 25px !important;
  }
} */
@media (min-width:1400px) and (max-width:2000px) {
  .fixedImage{
    width: 100% !important;
    height: auto !important;

  }
  .fixedvideo{
    width: 100% !important;
    height: auto !important;
  }
}