.maincollegecontainer h1{  
    font-size: 35px;
    font-weight: bold;
    line-height: 40px;
}

.maincollegecontainer p{
    font-size: 20px;
    line-height: 24px
}
.maincollegecontainer a{
    font-size: 18px;
    line-height: 25px;
    text-decoration: underline !important;
    color: #01A3d8 !important;

}
.maincollegecontainer h4{
    font-size: 36px;
    line-height:46px;
    text-align: center;
    font-weight: 600;
    padding: 30px 0;
}
.maincollegecontainer li{
    font-size: 20px;
    line-height: 26px;
}
.maincollegecontainer{
    padding: 60px 0;
}
.maincollegecontainer h5{
    font-size: 26px;
    line-height: 30px;
}
.mainheadingcollege{
    padding-bottom: 30px;
}
.formcontainer1{
    width: 50%;
    margin-bottom: 20px;
}
.formgroupcontainer{
    display: flex;
    width: 100%;
    gap: 2%;
}
.formcontainer{
    width: 100%;
}
.phoneconatiner{
    width:100% !important;
 height: 50px !important;
    border-radius: 6px !important;
    border: 1px solid #cccccc !important;
}
.customemailinput{
    border-radius: 6px !important;
    border: 1px solid #cccccc  !important;
    height: 50px !important;

}

/* Ensure the custom class is applied to the button */
.customresumeinput{
    height: 50px !important;
    border: 1px solid #cccccc !important; 

}

 .customresumeinput::file-selector-button {
    /* font-weight: bold; */
    /* color: dodgerblue; */
    padding: 0.5em;
    border: 1px solid #cccccc; 
    border-radius: 3px;
    height: 50px !important;
    cursor: pointer; 
  }
.customtextinput{
    width: 100%;
    padding: 10px;
    margin-top: 0px !important;
    height: 50px !important;
    border: 1px solid #cccccc !important;  
}
.recaptchacontainer{
    /* width: 50%; */
    margin: 20px 0;
}
.buttoncontainer button{
    height: 50px !important;
}

.formsection{
    /* background: #fff; */
    /* border: 1px solid #dee2e6; */
    /* display: flex; */
    
    justify-content: center;
    /* box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.2), 4px 4px 10px rgba(0, 0, 0, 0.2); */
    /* width: 50%; */
    /* padding: 100px 60px; */
    /* margin: 0 auto; */
}
.formgroupcontainer label{
    font-size: 25px !important;
}


@media (min-width:300px) and (max-width:767px) {
    .maincollegecontainer{
        padding: 25px 20px;
    }
    .mainheadingcollege{
        padding-bottom: 15px;
    }
    .maincollegecontainer h1{
        font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    }
    .maincollegecontainer p{
        font-size: 15px;
        line-height: 20px;

    }

    .maincollegecontainer li{
        font-size: 15px;
        line-height: 20px;

        padding-bottom: 5px;
    }
    .formgroupcontainer label{
        font-size: 20px !important;
    }
    .maincollegecontainer a{
        font-size: 14px;
    line-height: 16px;

    }
    .formsection{
        background: #fff;
        /* display: flex; */
        
        justify-content: center;
      
        /* margin: 0 auto; */
    }
    .formgroupcontainer{
        display: block;
        width: 100%;
        gap: 2%;
    }
    
    .formcontainer1{
        width: 100%;
    }
    .maincollegecontainer h4{
        font-size: 16px;
        line-height: 22px;
        text-align:start;
        padding: 20px 0;

    }
    .customtextinput{
        height: 35px !important;
    }
    .customemailinput{
     
        height: 35px !important;
    }
    .phoneconatiner{
        height: 35px !important;
    }
    .customresumeinput{
        height: 35px !important;
    }

    .customresumeinput::file-selector-button {
       
        padding: 0px;
        border: thin solid grey;
        border-radius: 3px;
        height: 35px !important;
        cursor: pointer; 
      }
      .formcontainer{
        margin-bottom: 20px;

      }
      .formcontainer1{
        margin-bottom: 20px;

      }
      .maincollegecontainer h5{
        
        font-size: 20px;
        line-height: 30px;
    
        }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .maincollegecontainer{
        padding: 50px 0px;
    }
    .mainheadingcollege{
        padding-bottom: 20px;
    }
    .maincollegecontainer h1{
        font-size: 22px;
    font-weight: bold;
    line-height: 31px;
    }
    .maincollegecontainer P{
        font-size: 16px;
    line-height: 22px;
    }
    .maincollegecontainer li{
        font-size: 16px;
        line-height: 22px;
    }
    .maincollegecontainer h4{
        font-size: 30px;
    line-height: 46px;
    /* text-align: center; */
    font-weight: 600;
    padding: 30px 0;

    }
    .maincollegecontainer h5{
        
        font-size: 24px;
        line-height: 30px;
    
        }
   
    .formgroupcontainer label{
        font-size: 20px !important;
    }
}