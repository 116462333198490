.speaker_banner {
    background-image: url('/public/speaker/bannerr.png');
    background-size: cover;
    background-position: center;
    background-color: #44574369;
    background-repeat: no-repeat;
    height: 95vh;
}
.banner_hd{
font-size: 41px;
font-weight: 600;
line-height: 51.25px;
margin-top: 45px;
/* color: #FEEBC3; */

}
.speaker_row {
    width: 70%;
    margin: auto;
}

.speaker_heading{
font-size: 24px;
font-weight: 600;
margin-bottom: 20px;
line-height: 30px;
}
.speaker_text{
line-height: 28px;
color:#484848
}

.formBox{
    border: 1px solid #D8D8D8;
    padding: 25px;
    margin-top: 40px;
    box-shadow: 0px 0px 7px 0px #00000040;
    border-radius: 10px;
}

.calendarInput{
    display: block !important;
    width: 100% !important;
    padding: .375rem .75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: var(--bs-body-color) !important;
    appearance: none !important;
    background-color: var(--bs-body-bg) !important;
    background-clip: padding-box !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    border-radius: var(--bs-border-radius) !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.calendarInput:focus {
    color: var(--bs-body-color) !important;
    background-color: var(--bs-body-bg) !important;
    border-color: #86b7fe !important;
    outline: 0 !important;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) !important;
}
.speakerradio input[type="radio"]{
    appearance: radio; /* Show default radio button */
    
    display: inline-block;
    width: auto;
    height: auto;
    border: none; /* Removes custom borders */
    outline: none;
}

/* Target the checked state on the speaker page */
.speakerradio input[type="radio"]:checked {
    appearance: radio; /* Revert back to default checked appearance */
    background-color: initial; /* Remove any custom background color */
    box-shadow: none; /* Remove custom box-shadow */
    border: none; /* Remove custom borders */
    position: static; /* Reset any custom position properties */
  }
  
  .logospeaker{
    margin-top: 48px;
  }
  .speakerlogotext{
    padding-top: 48px;
  }
@media (max-width: 767px) {
    .speaker_row {
        width: 100%;
        padding: 0 20px;
    }
    .speaker_banner{
        background-size: cover;
        background-position: center;
        background-color: #44574369;
        background-repeat: no-repeat;
        height: auto;
    
    }
.speakerlogotext{
    display: flex;

    padding: 20px 0 50px 10px;
    flex-direction: column;
}
.logospeaker{
    width: 200px;
   
}
.logospeaker img{
    width: 100px;
}

.banner_hd{
    font-size: 20px;
    line-height: 24px;
    margin-top: 0px;
}
.booknowbutton{
    padding: 4px 15px;
    border-radius: 30px;
    color: white;
    font-weight: 500;
    border: none;
    box-shadow: 0px 4px 4px 0px #00000040;
}
.speaker_heading{
    font-size: 20px;
    line-height: 24px;
}
.speaker_text{
    font-size: 12px;
    line-height: 18px;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
    .speaker_banner{
        background-size: cover;
        background-position: center;
        background-color: #44574369;
        background-repeat: no-repeat;
        height: auto;
    
    }
    .speakerlogotext{
        padding: 60px 0;
    }

    .logospeaker{
        margin-top: 0px;
    }
    .banner_hd{
        font-size: 25px;
        line-height: 30px;
        margin-top: 0px;
    }
    .speaker_row{
        width: 100%;
    }
    
}