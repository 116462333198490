.authBox{
    border: 1px solid #D8D8D8;
    padding: 25px;
    margin-top: 40px;
    box-shadow: 0px 0px 7px 0px #00000040;
    border-radius: 10px;
}
.custominput{
    width: 100% !important;
    border-radius: 0px !important;
    height: 100% !important;
    padding: 10px 10px !important;
    border-radius: 5px !important;
    /* border: none; */
    border: 1px solid #ccc !important;
}
.CenterSucesscontainer p{ 
    font-size: 20px;
    line-height: 25px;
}
.CenterSucesscontainer{
    padding-top: 40px;
}
.CenterSucesscontainer h2{
    font-size: 25px;
    line-height: 30px;
    font-weight: bold;
}

@media (min-width:300px) and (max-width:767px) {
    .CenterSucesscontainer p{ 
        font-size: 12px;
        line-height: 16px;
    } 
    .CenterSucesscontainer{
        padding-top: 20px;
    }
    .CenterSucesscontainer h2{
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
    
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .CenterSucesscontainer p{ 
        font-size: 18px;
        line-height: 22px;
    } 

    .CenterSucesscontainer{
        padding-top: 30px;
    }
    .CenterSucesscontainer h2{
        font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    }
   
}