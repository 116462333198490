.mainhbcu{
  padding-top: 30px;
    
}
.mainhbcu h1{
   
    font-size: 46px;
    font-weight: bold;
    line-height: 58px;
    color: #ffffff;
}

/* .newmainhbcu{
    position: absolute;
    top: 17%;
    right: 0;
    bottom: 0;
    width: 50%;
 
    padding: 0 50px;
 
} */
.mainhbcu p{
    margin-top: 30px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: #ffffff;
    

}
.hbcuimageconatiner{
  position: relative;
  background-color: #000;
}
.hbcucontainer{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    padding: 0px 60px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.imagehbcu{
    align-content: end;
}
.hbcuhead{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width:300px) and (max-width:767px) {
    
.mainhbcu p{
    margin-top: 5px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
margin-bottom: 0px;
font-family: 'Anonymous Pro', monospace;  


}
.mainhbcu h1{
  
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    font-family: 'Anonymous Pro', monospace;  

}

.mainhbcu{
    padding: 20px 20px;
   
}
.hbcucontainer{
    width: 100%;
    padding: 0px;
}
.imagehbcu img{
    width: 100%;
}
}

@media (min-width:768px) and (max-width:1024px) {

    /* .mainhbcu{
        position: absolute;
        top: 10%;
        right: 0;
        bottom: 0;
        width: 50%;
        padding: 0 50px;
    } */
     .mainhbcu h1{
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        color: #ffffff;
    
     }
    .mainhbcu p{
        margin-top: 9px;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        color: #ffffff;

    
    }
    .imagehbcu img{
        width: 100%;
    }
    
}