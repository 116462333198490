.clor_black {
    color: #000 !important;
}

.book_top_section {
    background-image: url("../../../public/book/hero_section_bg.png");
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    align-items: center;
    background-size: cover;
}

.book_top_content {
    padding-block: 85px;
}

.book_top_content p {
    font-size: 50px;
    line-height: 50px;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
}

.book_heading {
    font-size: 50px;
    font-weight: bold;
    line-height: 55px;
    margin-bottom: 12px;
    color: #ffffff;
}

.book_text {
    line-height: 24px;
    color: #484848;
    font-size: 20px;
    margin-bottom: 25px;
    color: #ffffff;

}

.book_done_button button {
    background-color: #01A3D8;
    color: white;
    border-radius: 30px;
    font-size: 15px;
    /* margin-top: 30px; */
    border: none;
    padding: 10px 32px;

}

.thechruchlead_wrapper {
    background-color: #01A3D8;
}

.modeled_after_divine {
    background-image: url("../../../public/book/holdinghands.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100vh; */


}
.church_done_button button{
    
    color:#191919;
    border-radius: 30px;
    font-size: 15px;
    margin-top: 30px;
    border: none;
    padding: 10px 32px;
}

.content_modeled_wrapper {
    display: flex;
    align-items: center;
    padding: 50px 0;
    /* height: 100vh; */
    background: rgb(25, 25, 25, 0.77);
    /* padding: 23px 50px 43px 76px; */
}

.thechruchlead_main {
    padding: 25px 0px 27px 0px;
}

.thechruchlead_banner {
    margin-bottom: -54px;
}

.courts_p_text {
    font-size: 18px;
    line-height: 24px;
}

.courts_heading_text {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.prolife_p_text {
    font-weight: 600;
}

.bymiketitle {
    font-size: 18px;
    font-weight: bold;
}

.lifapprochneeded_wrapper {
    color: black;
    padding-block: 60px;
}
.mainlife{
    text-align: center;
}
.life_approach_left_p {
    margin-top: 30px;
}

.life_approach_left_p p {
    text-align: left;
    font-size: 20px;
    line-height: 24px;
}

.mikename {
    font-weight: bold;
    line-height: 24px;
    font-size: 20px;
    
}
    

.life_approach_heading {
    font-size: 35px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 12px;
    color: #191919;
}
.life_approach_headingPro{
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 12px;
    color: #191919; 
}

.life_approach_p p {
    line-height: 24px;
    color: #333333;
    font-size: 20px;
    margin-bottom: 25px;
}

.life_approach_p p span {
    font-weight: bold;
}

.life_approach_prolife {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
}

.life_approach_prolife p {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
}

.wisdom_heading {
    font-size: 35px;
    font-weight: bold;
    line-height: 64px;
    margin-bottom: 12px;
    color: #ffffff;
}

.wisdom_text {
    font-size: 20px;
    line-height: 24px;
}

.wisdom_bold {
    font-weight: bold;
}

.matters_wrapper {
    padding: 50px 0;
}

.matters_h3 {
    font-weight: bold;
    font-size: 27px;
    line-height: 37px;
    margin-bottom: 18px;
}

.matters_p {
    font-size: 20px;
    line-height: 24px;
    /* margin-bottom: 50px; */
}

.matters_p span {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;

}

.banner_parent_wrapper {
    margin: auto !important;
}

.right_section_life_approach {
    padding-left: 60px;
}

.turnround_wrapper {
    background-color: #01A3D8;
    padding: 50px 0;
}

.trunround_heading {
    font-size: 37px;
    line-height: 52px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 29px;
}

.trunround_text {
    font-size: 15px;
    line-height: 30px;
    color: #ffffff;

}

.standcontainer h2 {
    font-size: 54px;
    line-height: 67px;
    font-weight: bold;
    color: #191919;
}

.standcontainer {
    text-align: center;
    padding: 100px 0px;
}

.life_approach_mike {
    display: flex;
    /* align-items: center; */
    padding-bottom: 30px;
}

/* Media Query for Mobile Devices */
@media (min-width: 300px) and (max-width: 768px) {
    .thechruchlead_banner {
        margin-block: 30px !important;
    }

    .book_top_content {
       padding: 50px 20px;
    }

    .book_top_content p {
        font-size: 20px;
        line-height: 24px;
    }

    .book_heading {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
    }
 .book_text {
    margin-bottom: 30px;
 }
    .life_approach_mike{
        padding: 0px;
    }

    .life_approach_heading {
        font-size: 20px;
        line-height: 24px;
        padding: 0 20px;
    
    }

    .wisdom_heading {
        font-size: 20px;
        line-height: 24px;
    }

    .wisdom_text {
        font-size: 12px;
        line-height: 18px;
    }

    .courts_p_text {
        font-size: 12px;
        line-height: 18px;
    }
 .trunround_text{
    font-size: 12px;
    line-height: 18px;
 }
    .courts_heading_text{
        font-size: 12px;
        line-height: 18px;
    }
    .life_approach_p p {
      
        font-size: 12px;
        line-height: 18px;
    }
    .life_approach_p{
        padding: 0 20px;
    }
    .matters_h3 {
        font-size: 20px;
        line-height: 24px;
    }

    .matters_wrapper {
        padding: 50px 20px;
    }

    .trunround_heading {
        font-size: 20px;
        line-height: 24px;
    }

    .life_approach_mike {
        display: inline !important;
    }

    .life_approach_left_p {
        margin-left: 0px;
       padding: 30px 20px;
       margin-top: 0px;
    }
    .thechruchlead_main{
        padding: 0 20px;
    }

    .content_modeled_wrapper {
        height: 100%;
        padding:  20px ;
    }
    .church_done_button button{
    color: #191919;
    border-radius: 30px;
    font-size: 13px;
    margin-top: 20px;
    border: none;
    padding: 8px 20px;
    font-weight: bold;
    }

    .lifapprochneeded_wrapper {
        padding-block: 30px;
    }

    .matters_p {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 18px;
    }

    .matters_p span {
        font-size: 12px;
        line-height: 18px;
    }

    .book_done_button button {
   
        margin-top: 20px;
     
    
    }

    .turnround_wrapper {
        padding: 50px 20px 50px 20px;
    }

    .standcontainer {
        padding: 0px 0px 0px 0px;
    }

    .standcontainer h2 {
        font-size: 20px;
        line-height: 24px;
        padding: 40px 20px 40px 20px;
    }

    .right_section_life_approach {
       padding: 0px;
    }
    .modeled_after_divine {
        height: 100%;
        
    }
    .book_done_button button {
   
        margin-top: 30px;
     
    
    }
    .life_approach_left_p p{
        font-size: 12px;
        line-height: 18px;

    }
    .mikename {
        font-size: 12px;
        line-height: 18px;  
    }

}


/* Media Query for low resolution  Tablets, Ipads */
/* @media (min-width: 481px) and (max-width: 768px) {
    .thechruchlead_banner {
        height: 70%;
        margin-block: auto;
    }

    .thechruchlead_banner_parent_div {
        margin: auto !important;
    }

    .thechruchlead_banner {
        margin-block: 30px !important;
    }

    .book_top_content p {
        font-size: 24px;
        line-height: 28px;
    }

    .book_heading {
        font-size: 35px;
        line-height: 40px;
    }

    .life_approach_heading {
        padding: 20px;
        font-size: 35px;
        line-height: 40px;
    }

    .wisdom_heading {
        font-size: 35px;
        line-height: 40px;
    }

    .wisdom_text {
        font-size: 16px;
        line-height: 25px;
    }

    .matters_h3 {
        font-size: 35px;
        line-height: 40px;
    }

    .matters_wrapper {
        padding: 45px 20px 45px 20px;
    }

    .trunround_heading {
        font-size: 35px;
        line-height: 40px;
    }

    .left_banner_section {
        text-align: center;
    }

    .life_approach_left_p {
        padding-top: 30px;
    }

    .life_approach_mike {
        display: inline !important;
    }

    .content_modeled_wrapper {
        height: 100%;
        padding:  20px ;
    }

    .right_section_life_approach {
        padding-left: 0px;
    }
    .modeled_after_divine {
        height: 100%;
        
    }
    .standcontainer h1 {
        font-size: 30px;
        line-height: 34px;
        
    }
    .standcontainer{
        padding: 40px 20px 40px 20px;
    }

} */

@media  (min-width: 768px) and (max-width: 1024px) {
    .thechruchlead_banner1 {
        margin-bottom: 80px !important;
    }

    .life_approach_left_p {
        padding-top: 45px;
    }

    .life_approach_mike {
        padding-bottom: 20px !important;
    }

    .content_modeled_wrapper {
        height: 100%;
        padding:  20px;

    }
    .modeled_after_divine{
        height: 100%;
    }

    .right_section_life_approach {
        padding-left: 0px;
    }
    .wisdom_heading{
    font-size: 40px;
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 12px;
    color: #ffffff;
    }
    .wisdom_text{
        font-size: 25px;
        line-height: 30px;
    
    }
    .standcontainer h1 {
        font-size: 30px;
        line-height: 34px;
        /* padding: 40px 20px 40px 20px; */
    }
    .standcontainer{
        padding: 40px 20px 40px 20px;
    }
    .book_done_button button {
   
        margin-top: 30px;
     
    
    }
    
}