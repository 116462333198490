.centerHeading {
  font-size: 25px;
  font-weight: 600;
  color: black;
  text-align: center;
}

.serchInput {
  width: 50% !important;
  margin: auto !important;
}

.serchInputsec {
  padding: 16px 20px !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #999999 !important;
  border-top-left-radius: 14px !important;
  border-bottom-left-radius: 14px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: none !important;
}

.sercState {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: none !important;
}

.serchInputbtn{
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  padding-top: 16px !important;
  border-color: #06a1d7 !important;
  background-color: #06a1d7 !important;
  padding-bottom: 16px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

}

/* .serchInputbtn {
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  border-color: #06a1d7 !important;
  background-color: #06a1d7 !important;
} */
.serchInputbtn:hover {
  background-color: #006f96 !important;
  border-color: #006f96 !important;
  color: white !important;
}

.suggestion {
  width: 38%;
  max-height: 250px;
  overflow: auto;
}
.suggestion a {
  font-size: 20px !important;
}
.maincentercontainer{
  background-color: #06A1D7;
  padding: 65px 0px 70px 0
}
.dashboard_row {
  width: 100%;
  margin: auto;
  /* margin-left: 50px; */
  color: white;
}
.centerCard {
  box-shadow: 4px 5px 21px 0px #00000012;
  padding: 23px;
  background: #a8e5f1;
  color: black;
  border-radius: 15px;
  /* padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px; */
}
.drpdwn {
  border-radius: 10px !important;
  /* margin-top: 5px !important; */
  min-width: 7rem !important;
  border: 1px solid !important;
}
.stateDrpDwm {
  top: 80px;
  left: 125px;
  border-radius: 10px !important;
  margin-top: 5px !important;
  border: 1px solid !important;
}

.stateDrpDwm a:hover {
  border: none;
}

.drpdwn a:hover {
  border: none;
}

.selectedstatetag{
  background: #01A3d8;
    border-radius: 20px;
    padding: 6px 12px;
    color: #ffffff;
    font-size: 13px;
    cursor: pointer;
}
.selectedstatecontainer{
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .centerHeading {
    font-size: 20px;
  }
  .serchInput {
    width: 98% !important;
  }

  .serchInputsec {
    font-size: 14px !important;
  }

  .serchInputbtn {
    font-size: 14px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .suggestion {
    width: 80%;
  }
  .suggestion a {
    font-size: 16px !important;
  }

  
}


@media (min-width:768px) and (max-width:1024px) {
  .stateDrpDwm{
    left: 60px;
  }
  .drpdwn{
    right: 4px;
  }
}

@media (min-width:300px) and (max-width:767px) {
  .drpdwn{
    right: 0px !important; 
  }
  .stateDrpDwm{
    right: 126px !important;
   left:auto
  }
}
