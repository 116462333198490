
/* privacypolicy.module.css */

.privacyPolicyContainer {
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .privacyHeading {
    font-size: 39px;
    font-weight: bold;
    text-align: center;
    color: #343a40;
  }
  
  .sectionHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-left: 10px;
    color: #495057;
  }
  
  .privacyText {
    font-size: 18px;
    line-height: 1.6;
    color: #6c757d;
    margin-bottom: 20px;
    margin-left: 10px;
    text-align: justify;
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .privacyHeading {
      font-size: 1.5rem;
    }
  
    .sectionHeading {
      font-size: 1.2rem;
    }
  
    .privacyText {
      font-size: 0.9rem;
    }
  }
  